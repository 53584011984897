<template lang="pug">
  Meeting(
    :meetId="$route.params.meetId"
    :username="username"
    @endMeet.once="endMeet"
  ) 
</template>

<script>
import Meeting from "./Meeting";
import { mapState } from "vuex";
import _get from "lodash/get";

export default {
  name: "MeetingWrapper",

  components: {
    Meeting
  },

  data: () => ({
    endTurnStatuses: ["FINALIZED", "REVOKED", "CANCELED_BY_USER"]
  }),

  watch: {
    status(val, oldVal) {
      if (
        (val === "ABSENT" || val === "WAITING_TO_BE_CALLED") &&
        oldVal === "ANNOUNCED"
      )
        this.$router.back();
    }
  },

  computed: {
    ...mapState(["client_data", "turn"]),

    username() {
      let name = _get(this.client_data, "firstName");
      let lastname = _get(this.client_data, "lastName");
      return name || lastname
        ? `${name || ""}${lastname ? " " : ""}${lastname || ""}`
        : null;
    },

    status() {
      return this.turn?.status;
    }
  },

  methods: {
    endMeet(iFinishMeet) {
      if (
        this.endTurnStatuses.find(status => status === this.turn?.status) ||
        iFinishMeet
      )
        this.$router.push({ name: "Canceled" });
      else this.$router.back();
    }
  }
};
</script>
